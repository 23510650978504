/* src/Navigation.css */
.navbar {
  font-size: 18px; /* Mărește dimensiunea textului */
  background-color: #022141; /* Fundalul meniului - potrivit cu footer-ul */
  border-bottom: 1px solid #ffffff; /* Adaugă un border la partea de jos */
}

.navbar-brand,
.nav-link {
  color: white !important; /* Text alb */
}

.navbar-brand:hover,
.nav-link:hover {
  color: #17a2b8 !important; /* Culoare la hover */
}
.navbar-toggler-color {
  background-color: #007bff; /* sau orice altă culoare dorită */
}

/* src/Home.css */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

.my-container {
  color: white;
}

.bg-gradient {
  background: linear-gradient(135deg, #3498db, #f39c12);
}

.main-heading {
  color: white;
  font-size: 3em;
}

.container p {
  color: #333;
  font-size: 1.5em;
}
/* styles.css sau Home.css */
.jumbotron-bg {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #fff;
}

.display-4 {
  font-size: 3rem;
}

.lead {
  font-size: 1.25rem;
}
/* Home.css */
.custom-card {
  width: 75%;
  margin: 0 auto;
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Adăugați această linie pentru a alinia în centrul orizontal */
}

.custom-card .card-body {
  width: 90%;
  text-align: center;
}

.custom-card .card-title {
  font-size: 2rem;
  margin-bottom: 15px;
}

.custom-card .btn {
  width: 80%;
  font-size: 1.2rem;
}

/* src/Footer.css */
.footer {
  background-color: #343a40; /* sau orice altă culoare pe care doriți să o utilizați */
  color: white;
  padding: 10x;
  height: 5vh; /* 5% din înălțimea vizibilă a ecranului */
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footer p {
  color: aliceblue;
}

@media (max-width: 768px) {
  .footer {
    font-size: 0.8rem;
    padding: 5px; /* Reducem puțin padding-ul pentru a se potrivi pe ecrane mici */
  }
}

/* Adaugă acest cod în fișierul de stilizare */

/* Stilizare pentru formular */
form {
  max-width: 600px;
  margin: 0 auto;
}

/* Stilizare pentru label-uri */
label {
  display: block;
  margin-bottom: 8px;
}

/* Stilizare pentru input-uri */
input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}

/* Stilizare pentru butonul de submit */
button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Stilizare pentru secțiunile din formular */
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007bff;
}

.contract-form-container {
  margin-bottom: 80px; /* sau orice valoare care este cel puțin înălțimea footer-ului */
}

body {
  overflow: auto;
}

.container {
  margin-bottom: 100px; /* Ajustează valoarea în funcție de necesități */
}
